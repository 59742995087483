import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"

const MangaCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="マンガ広告で効果アップ！ | マンガ×SNSキャンペーン"
          pagedesc="マンガとSNSキャンペーンの掛け合わせで効果倍増！"
          pagepath={location.pathname}
        />
        <main className="manga">
          <section className="fv md:pt-20 px-0 pt-6">
            <div className="md:pl-20 px-4">
              <div className="fv__copy">
                <div className="md:block hidden">
                  <img
                    layout="constrained"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/top_text_title_pc.png"
                    alt="頭一つ飛び抜ける!!マンガ広告で効果アップ!!!"
                  />
                </div>
                <div className="md:hidden block">
                  <img
                    layout="constrained"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sp_top_text_title.png"
                    alt="頭一つ飛び抜ける!!マンガ広告で効果アップ!!!"
                  />
                </div>
              </div>
              <div className="fv__catch md:-mt-8">
                <div className="md:block hidden">
                  <img
                    layout="constrained"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/fv_catch_pc.png"
                    alt="SNSキャンペーンと相性バツグン!!・最低価格10万円〜・キャンペーンに最適な作風を選べる!!!"
                  />
                </div>
                <div className="md:hidden block">
                  <img
                    layout="constrained"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/top_text_fukidashi.png"
                    alt="SNSキャンペーンと相性バツグン!!・最低価格10万円〜・キャンペーンに最適な作風を選べる!!!"
                  />
                </div>
              </div>
            </div>
            <div className="md:m-auto text-center pt-10 md:pt-0 relative md:z-10">
              <a
                href="https://hub.park-lot.com/contact"
                className="manga__cta-btn"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>
            </div>
          </section>
          <section className="merit pb-12 md:pb-32 bg-white md:pt-0 pt-10">
            <div className="merit__heading m-auto">
              <h2 className="text-center md:pt-32 md:pt-14 pt-0 md:px-0 px-6 md:mb-20 mb-7">
                <img
                  layout="constrained"
                  alt="掛け合わせで効果倍増!!!マンガ × SNSキャンペーン"
                  src={`https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec01_title_pc.png`}
                />
              </h2>
              <div className="merit__heading-img mx-auto md:mb-24 mb-16 px-6 md:px-0">
                <img
                  layout="constrained"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec01_top.png"
                  alt="マンガ × SNSキャンペーン"
                />
              </div>
              <div className="merit__points md:flex md:gap-16 md:mx-auto font-noto px-6 md:px-0">
                <div className="md:flex-1 mb-8 md:mb-0">
                  <div className="mb-4">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec01_img01.png"
                      layout="constrained"
                      alt="二次利用可能！"
                    />
                  </div>
                  <h3 className="md:text-center md:mb-4 mb-2 font-bold text-2xl">
                    二次利用可能！
                  </h3>
                  <p className="md:text-xl">
                    自社ホームページにのせたり、印刷物に使用することも可能！
                    <br />
                    youtubeの動画広告の素材としても利用可能。
                  </p>
                </div>
                <div className="md:flex-1 mb-8 md:mb-0">
                  <div className="mb-4">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec01_img02.png"
                      layout="constrained"
                      alt="最低価格10万円〜"
                    />
                  </div>
                  <h3 className="md:text-center md:mb-4 mb-2 font-bold text-2xl">
                    最低価格10万円〜
                  </h3>
                  <p className="md:text-xl">
                    選ぶ作家やページ数、企画の内容により金額が変わります。最低価格は10万円からです。
                    <br />
                    ご予算に合わせたご提案も可能です！
                  </p>
                </div>
                <div className="md:flex-1">
                  <div className="mb-4">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec01_img03.png"
                      layout="constrained"
                      alt="企画から相談可能"
                    />
                  </div>
                  <h3 className="md:text-center md:mb-4 mb-2 font-bold text-2xl">
                    企画から相談可能
                  </h3>
                  <p className="md:text-xl">
                    企画の詳細が決まってなくても大丈夫！PARKLoTは企画立ち上げからご相談に乗ります。
                    <br />
                    キャンペーンをやったことがない方も安心です。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="howto md:py-32 py-20">
            <div className="howto__container px-6 md:px-0">
              <h2 className="text-center md:mb-10 mb-9">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec02_title_pc.png"
                  alt="こんな使い方ができます"
                  layout="constrained"
                />
              </h2>
              <div className="md:flex md:gap-12 md:items-center mb-8">
                <div className="mb-4 md:mb-0 md:flex-1">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec02_img01.png"
                    layout="constrained"
                    alt="マンガで商品紹介!!"
                  />
                </div>
                <div className="howto__serif md:p-7 p-4 md:flex-1">
                  <h3 className="font-noto font-bold text-xl mb-2 md:mb-6">
                    マンガで商品紹介!!
                  </h3>
                  <p className="font-bold font-noto md:text-xl">
                    自社の商品をマンガで紹介しましょう！
                    <br />
                    利用シーンをストーリーで伝えれば、読者も釘付け間違いなし。
                  </p>
                </div>
              </div>
              <div className="md:flex md:gap-12 md:items-center">
                <div className="mb-4 md:mb-0 md:flex-1">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec02_img02.png"
                    layout="constrained"
                    alt="マンガ×動画で効果倍増!!"
                  />
                </div>
                <div className="howto__serif md:p-7 p-4 md:flex-1">
                  <h3 className="font-noto font-bold text-xl mb-2 md:mb-6">
                    マンガ×動画で効果倍増!!
                  </h3>
                  <p className="font-bold font-noto md:text-xl">
                    マンガから動画広告を作成することも可能です。
                    動画投稿サイトにも投稿すれば効果は倍増です！
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="starting-flow py-12 px-6 md:py-32 md:px-0">
            <div className="text-center mb-8 md:mb-24">
              <img
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec03_title_pc.png"
                layout="constrained"
                alt="キャンペーン開催までの流れ"
              />
            </div>
            <div className="container m-auto">
              <div className="block md:hidden mb-8">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec03_img_sp.png"
                  layout="constrained"
                  alt="お問い合わせ、打ち合わせ、契約締結、企画（企画会議・マンガ家選定）、制作（ネーム制作・マンガ執筆）、キャンペーン開始"
                />
              </div>
              <div className="md:block hidden md:mb-20">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec03_img_pc.png"
                  layout="constrained"
                  alt="お問い合わせ、打ち合わせ、契約締結、企画（企画会議・マンガ家選定）、制作（ネーム制作・マンガ執筆）、キャンペーン開始"
                />
              </div>
              <p className="font-noto font-bold text-center md:text-2xl mb-4 md:mb-8">
                気になったらまずはお問い合わせ！
              </p>
              <div className="m-auto text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  className="manga__cta-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせ
                </a>
              </div>
            </div>
          </section>
          <section className="plan py-12 md:py-32 px-4">
            <h2 className="plan__heading text-center md:mb-32 mb-8 mx-auto">
              <img
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec04_title.png"
                alt="料金プラン"
              />
            </h2>
            <div className="plan__container md:py-20 md:px-32 py-6 px-11 font-noto md:mb-14 mb-8 mx-auto">
              <h3 className="font-bold text-2xl md:text-3xl mb-4 text-center">
                基本費用
              </h3>
              <p className="text-center font-bold md:text-7xl text-4xl mb-8 md:mb-16">
                <span className="md:text-9xl text-6xl font-black">10</span>
                万円〜
              </p>

              <h3 className="font-bold text-2xl md:text-3xl mb-4 md:mb-6 text-center">
                オプション
              </h3>
              <ul className="flex flex-wrap md:gap-y-16 gap-y-4 w-full">
                <li className="plan__option">
                  <div className="h-full w-full mx-auto my-0 flex flex-col items-center justify-center">
                    <p className="mb-1 md:mb-5 text-sm md:text-2xl">1pあたり</p>
                    <p className="text-xl md:text-4xl font-black">25,000円〜</p>
                  </div>
                </li>
                <li className="plan__option">
                  <div className="h-full w-full mx-auto my-0 flex flex-col items-center justify-center">
                    <p className="mb-1 md:mb-5 text-sm md:text-2xl">
                      漫画家別費用
                    </p>
                    <p className="text-xl md:text-4xl font-black">要相談</p>
                  </div>
                </li>
                <li className="plan__option">
                  <div className="h-full w-full mx-auto my-0 flex flex-col items-center justify-center">
                    <p className="mb-1 md:mb-5 text-sm md:text-2xl">企画立案</p>
                    <p className="text-xl md:text-4xl font-black">
                      150,000円〜
                    </p>
                  </div>
                </li>
                <li className="plan__option">
                  <div className="h-full w-full mx-auto my-0 flex flex-col items-center justify-center">
                    <p className="mb-1 md:mb-5 text-sm md:text-2xl">
                      パンフレット作成
                    </p>
                    <p className="text-xl md:text-4xl font-black">
                      100,000円〜
                    </p>
                  </div>
                </li>
                <li className="plan__option">
                  <div className="h-full w-full mx-auto my-0 flex flex-col items-center justify-center">
                    <p className="mb-1 md:mb-5 text-sm md:text-2xl">動画製作</p>
                    <p className="text-xl md:text-4xl font-black">要相談</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="plan__container md:py-20 md:px-32 py-6 px-11 font-noto mx-auto md:mb-20 mb-8">
              <h3 className="font-bold text-2xl md:text-3xl md:mb-16 mb-4 text-center">
                制作事例
              </h3>
              <div className="md:flex md:items-center md:gap-8 mb-6">
                <div className="md:w-2/6 w-5/6 mx-auto mb-4 md:mb-0">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec04_img01.png"
                    alt="サービス紹介のマンガ広告"
                  />
                </div>
                <div>
                  <p className="text-sm md:text-2xl mb-1 md:mb-0 font-bold text-center md:text-left">
                    サービス紹介のマンガ広告
                  </p>
                  <p className="text-2xl mb-2 font-bold text-center md:text-left">
                    <span className="text-4xl">12</span>万円
                  </p>
                  <p className="mb-1">マンガ家：田中太郎　ページ数：8p</p>
                  <p className="text-xl">
                    株式会社サンプル様のサービス「SAMPLE」のマンガ広告を作成。サービスを利用する男子学生を主人公にしたマンガです。
                    <br />
                    広告はPV数100万を獲得し、キャンペーン開始前と比較して約10倍の購入数増加につながりました。
                  </p>
                </div>
              </div>
              <div className="md:flex md:items-center md:gap-8">
                <div className="md:w-2/6 w-5/6 mx-auto mb-4 md:mb-0">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/manga/sec04_img02.png"
                    alt="サービス紹介のマンガ広告"
                  />
                </div>
                <div>
                  <p className="text-sm md:text-2xl mb-1 md:mb-0 font-bold text-center md:text-left">
                    サービス紹介のマンガ広告
                  </p>
                  <p className="text-2xl mb-2 font-bold text-center md:text-left">
                    <span className="text-4xl">12</span>万円
                  </p>
                  <p className="mb-1">マンガ家：田中太郎　ページ数：8p</p>
                  <p className="text-xl">
                    株式会社サンプル様のサービス「SAMPLE」のマンガ広告を作成。サービスを利用する男子学生を主人公にしたマンガです。
                    <br />
                    広告はPV数100万を獲得し、キャンペーン開始前と比較して約10倍の購入数増加につながりました。
                  </p>
                </div>
              </div>
            </div>
            <p className="font-noto font-bold text-center md:text-2xl mb-4 md:mb-8">
              気になったらまずはお問い合わせ！
            </p>
            <div className="m-auto text-center">
              <a
                href="https://hub.park-lot.com/contact"
                className="manga__cta-btn"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default MangaCampaign
